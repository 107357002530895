.MediaLibrary .form-check-image {
    position: relative;
    overflow: hidden;
}

.MediaLibrary .form-check-image.active .form-check-wrapper {
    border-color: var(--kt-primary);
}

.MediaLibrary .form-check-image img {
    max-width: 100%;
}

.MediaLibrary .form-check-custom {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: 0;
}

.MediaLibrary .form-check:not(.form-switch) .form-check-input[type=checkbox] {
    background-size: 60% 60%;
}

.MediaLibrary .form-check-custom.form-check-solid .form-check-input {
    border: 0;
    background-color: var(--kt-form-check-input-bg-solid);
}

.MediaLibrary .form-check {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0 !important;
}

.CustomizedFooter{
    justify-content: space-between !important;
}

.CustomizedFooter .btn-light{
margin-right: 15px !important;
}

.uplaodBtn {
    display: block !important;
    width: 150px !important;
    height: 41px !important;
    border-radius: 4px !important;
    background-color: #f5f8fa !important;
    padding-top: 10px !important;
    color: #7e8299 !important;
}

.uplaodBtn input{
    visibility: hidden !important;
}