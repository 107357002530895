//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';


.align-center{
    text-align: center;
}

.formMainContainer{
    max-width: 1000px;
    margin: 0 auto;
}

.pageTabContainer {
    border-bottom: #e3e3e3 1px solid;
}

.formContainer{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    max-width: 500px !important;
}

.FormSpace{
    padding: 20px;
}